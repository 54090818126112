import React, { useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import i18n from '../../i18n/index';
import './AboutMe.css'
export default function AboutMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  // const fadeInSubscription = ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const SCREEN_CONSTSANTS = {
    description: 
     
      i18n.t('about_me_descripcion'),
    highlights: {
      bullets: [
       
        i18n.t('bullets_1'),
        i18n.t('bullets_2'),
        i18n.t('bullets_3'),
        i18n.t('bullets_4'),
        i18n.t('bullets_5'),
        i18n.t('bullets_6'),
      
      ],

      heading:  i18n.t('Highlights_title'),
    },
  };
  const renderHighlight = () => {
    return SCREEN_CONSTSANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ));
  };
  return (
    <div className="about-me-container screen-container" id={props.id || ""}>
      <div className="about-me-parent">
        <ScreenHeading title={i18n.t("about_me")} subHeading={i18n.t("why_choose_me")} />
        <div className="about-me-card">
          <div className="about-me-profile"></div>
          <div className="about-me-details">
            <span className="about-me-description">
              {SCREEN_CONSTSANTS.description}
            </span>
            <div className="about-me-highlights">
              <div className="highlight-heading">
                <span>{SCREEN_CONSTSANTS.highlights.heading}</span>
              </div>
              {renderHighlight()}
            </div>
            <div className="about-me-options">
               <a href="#ContactMe">
              <button className="btn primary-btn"
              
              >
                {""}
                {i18n.t('Hire_Me')} {""}
              </button>
              </a>
              <a href="manuel avalos Cv.pdf" dowload="Manuel Avalos">
                <button className="btn highlighted-btn">{i18n.t('Get_Resume')}</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
