// esp.js
export const esp = {
    "Home": 'Inicio',
    "AboutMe": 'Sobre mí',
    "Resume": 'Currículum',
    "ContactMe": 'Contáctame',
    "greeting": "Hola, soy",
    "name": "Manuel Avalos",
    "from": "y soy Mexicano 🌮🌮 ",
    "role1": "Desarrollador web Full Stack SR 💻",
    "role2": "Desarrollador Apasionado 🤖",

    "role3": "Microsoft Azure 🌐",
    "role4": "HTML, CSS, JS 😃",
    "role5": " DevOps 🚀",
    "role6": "React/React Native Dev 🤳",
    "role7": "API REST 👀",
    "tagline": "Solo los locos que piensan que pueden cambiar el mundo, pueden cambiarlo realmente. -Steve Jobs",
    "Hire_Me": "Trabajemos juntos! 😊",
    "Get_Resume": "Descarga mi CV! 📂",
    "Profile_Github": "Github! 😏",
    "about_me": "Acerca de mí", 
    "why_choose_me": "¿Por qué elegirme 😏?",
    "about_me_descripcion": "Soy un desarrollador Full Stack, una persona apasionada por el desarrollo web y las tecnologías que lo hacen posible. Me encanta trabajar en proyectos desafiantes y crear soluciones innovadoras que resuelvan problemas del mundo real.Desde mi juventud, me ha interesado la programación y la tecnología, y he dedicado mi carrera a convertirme en un experto en el campo del desarrollo web. He adquirido habilidades tanto del lado del servidor como del lado del cliente, y puedo diseñar, desarrollar y mantener sitios y aplicaciones web de extremo a extremo.Como desarrollador Full Stack, tengo experiencia en una variedad de tecnologías, desde HTML, CSS y JavaScript en el front-end, hasta PHP, LARAVEL y Python en el back-end. Además, tengo habilidades en bases de datos como MySQL, así como en servidores web y herramientas de automatización. ",
    "Highlights_title": "Aquí hay algunos aspectos destacados sobre de mi",
    "bullets_1": "Capacidad para trabajar tanto en el lado del servidor como en el del cliente del desarrollo web.",
    "bullets_2": "Conocimiento de varias tecnologías, incluidos lenguajes de programación, bases de datos, servidores web y herramientas de automatización.",
    "bullets_3": "Capacidad para diseñar, desarrollar y mantener sitios y aplicaciones web de extremo a extremo.",
    "bullets_4": "Capacidad para colaborar con equipos y comunicarse de manera efectiva en entornos de trabajo distribuidos o remotos.",
    "bullets_5": "Flexibilidad para adaptarse a diferentes tecnologías y lenguajes de programación según sea necesario para cada proyecto.",
    "bullets_6": "Capacidad para trabajar en ambos lados del servidor y del cliente del desarrollo web.",
    "resume_details": "Mi historial formal",
    "education": "Educación",
    "work_experience": "Experiencia laboral 🤫",
    "programming_skills": "Habilidades de programación",
    "projects": "Proyectos",
    "interests": "Intereses",
    "platzy": "Platzy, Educación en línea",
    "platzy_description": "La comunidad de aprendizaje en línea donde desarrollas tus habilidades y potencias tu crecimiento profesional. <a href='https://platzi.com/r/Manuelavalos' target='_blank'>Ver perfil</a>",
    "platzy_toDate": "En progreso 🚀",
    "resume_job": "Cappa Software, REMOTO",
    "resume_job_description": "En mi rol actual, soy el responsable principal de orquestar la implementación de API, utilizando tecnologías de vanguardia como JavaScript, Bootstrap y PHP en el backend. Mi contribución se desarrolla en un entorno de microservicios, destacándome en la creación de soluciones altamente eficientes y escalables. Mi enfoque va más allá de la ejecución técnica, ya que me apasiona concebir y desarrollar soluciones innovadoras que no solo cumplen con los requisitos, sino que también elevan significativamente el rendimiento y la eficacia del proyecto. Estoy comprometido a ofrecer resultados que superen las expectativas y estoy emocionado por la oportunidad de aportar mi experiencia técnica y creatividad a su equipo.",
    "job_habilidades": "✅Capacidad para diseñar y desarrollar API altamente eficientes y escalables utilizando tecnologías como JavaScript, Bootstrap y PHP.",
    "job_habilidades2": "✅Conocimientos y experiencia en el desarrollo de aplicaciones basadas en microservicios, lo que permite la creación de soluciones altamente escalables y flexibles.",
    "resume_job2": "Cappa Software, REMOTO",
    "resume_job_description2": "Actualmente, lidero un equipo en la mejora y expansión de una plataforma de soluciones e-Learning dedicada a la capacitación empresarial. Mis responsabilidades incluyen la asignación de tareas, la organización de reuniones estratégicas y la gestión de proyectos.En mi rol, desarrollo módulos nuevos para la plataforma, utilizando PHP como lenguaje de programación. Además, diseño y creo API para conectar de manera efectiva con el frontend de la plataforma, mientras mantenemos una base de datos robusta en MYSQL.Para garantizar un flujo de trabajo eficiente, utilizamos herramientas como Notion para la planificación de sprints y enviamos informes periódicos detallados a nuestros clientes para mantenerlos informados sobre el progreso del proyecto. También, colaboro estrechamente con nuestros clientes para identificar y documentar sus requisitos específicos, asegurando que la plataforma cumpla con sus necesidades de capacitación empresarial de manera precisa y efectiva.Además, como parte integral de mi rol, realizo modificaciones en la vista de usuario para mejorar la experiencia del usuario, aplicando las mejores prácticas de diseño de interfaz y experiencia de usuario (UX) para garantizar que nuestra plataforma sea intuitiva y efectiva para nuestros usuarios finales.",
    "job_2_habilidades": "✅ Planificación estratégica · Base de datos relacional · Resolución de problemas · Desarrollo de API · PHP · MySQL · Notion · Experiencia de usuario · Gestión de proyectos · Comunicación con los clientes · Diseño de experiencia de usuario (UX) · Desarrollo de software · Trabajo en equipo",
    "projects_title_1" : "Agencia de desarrollo de sitios web",
    "projects_description_1" : "Nos enfocamos en poner a nuestros clientes en el centro de todo lo que hacemos, por eso hemos implementado un modelo de servicio al cliente especializado en el desarrollo de tecnología para brindarles un servicio excepcional.",
    "projects_subHeading_1" : "Visión estratégica, liderazgo, habilidades de comunicación y orientación al cliente.",
    "projects_title_2" : "Sitio web personal de portafolio",
    "projects_description_2" : "Un sitio web de portafolio personal para mostrar todos mis proyectos en un solo lugar.",
    "projects_subHeading_2" : "Tecnologías utilizadas: React js, HTML y CSS",
    "interests_title": "🤝Creador de contenido",
    "interests_description": "Me encanta compartir mi conocimiento con la comunidad, por eso me gusta crear contenido en mi tiempo libre. Además, me gusta aprender cosas nuevas y mantenerme actualizado con las últimas tecnologías.",
    "interests_subHeading": "influencer ",
    "interests_title2": "🤖Inteligencia Artificial",
    "interests_description2": "Me gusta aprender sobre IA y cómo puede ayudar a la humanidad en el futuro.",
    "interests_subHeading2": "Futuro IA",
    "comunidad": "Comunidad",
    "client": "Trabajos con Clientes",
  };
 

  
export default esp;