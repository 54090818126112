import React, { useEffect, useState } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";
import Comunidad from "../Comunidad/Comunidad";
import i18n from '../../i18n/index';

export default function Resume(props) {
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffSetStyle, setCarousalOffSetStyle] = useState({});
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? props.heading : ""}</span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + "-" + props.toDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span dangerouslySetInnerHTML={{ __html: props.subHeading }} />
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
      </div>
    );
  };
  
  const resumeBullets = [
    { label: i18n.t("work_experience"), logoSrc: "work-history.svg" },
    { label: i18n.t("education"), logoSrc: "education.svg" },
   
    { label: i18n.t("programming_skills"), logoSrc: "programming-skills.svg" },
    { label: i18n.t("projects"), logoSrc: "projects.svg" },
    { label: i18n.t("interests"), logoSrc: "interests.svg" },
  ];

  const programmingSkillDetails = [
    { skill: "JavaScript", ratingPercentage: 85 },
    { skill: "React Js", ratingPercentage: 20 },
    { skill: "HTML", ratingPercentage: 85 },
    { skill: "CSS", ratingPercentage: 80 },
    { skill: "Boostrap", ratingPercentage: 80 },
    { skill: "PHP", ratingPercentage: 82 },
    { skill: "Laravel", ratingPercentage: 82 },
    { skill: "Python", ratingPercentage: 20 },
    { skill: "Azure", ratingPercentage: 45 },
    { skill: "Mysql", ratingPercentage: 80 },
  ];

  const propject = [
    {
      title: i18n.t("projects_title_1"),
      duration: { fromDate: "2022", toDate: "Current" },
      description: i18n.t("projects_description_1"),
      subHeading: i18n.t("projects_subHeading_1"),
    },
    {
      title: i18n.t("projects_title_2"),
      duration: { fromDate: "2022", toDate: "2023" },
      description:
        i18n.t("projects_description_2"),
      subHeading: i18n.t("projects_subHeading_2"),
    },
  ];

  const resumeDetails = [
    <div className="resume-screen-container" key="work-experience">
    <div className="experience-container">
      <ResumeHeading
        heading={"Cappa Software, REMOTE"}
        subHeading={"FULL STACK DEVELOPER SENIOR"}
        fromDate={"2022"}
        toDate={"present"}
      />

      <div className="experience-description">
        <span className="resume-description-text">
        {i18n.t("resume_job_description")}
        </span>
      </div>
      <div className="experience-description">
        <span className="resume-description-text">
        {i18n.t("job_habilidades")}
        </span>
        <br />
        <span className="resume-description-text">
        {i18n.t("job_habilidades2")}
        </span>
        <br />
      </div>
      
    </div>
<br></br>
    <div className="experience-container">
      <ResumeHeading
        heading={"Mi Cursor, REMOTE - Temporal"}
        subHeading={"FULL STACK DEVELOPER SENIOR"}
        fromDate={"2023"}
        toDate={"present"}
      />

      <div className="experience-description">
        <span className="resume-description-text">
        {i18n.t("resume_job_description2")}
        </span>
      </div>
      <div className="experience-description">
        <span className="resume-description-text">
        {i18n.t("job_2_habilidades")}
        </span>
      
      </div>
      
    </div>
 
  </div>,
    <div className="resume-screen-container" key="education">
  
       <ResumeHeading
        heading={i18n.t("platzy")}
        subHeading={i18n.t("platzy_description") }
        fromDate={"2021"}
        toDate={i18n.t("platzy_toDate")}
      />
      <br></br>
          <ResumeHeading
        heading={"ITESCO, COATZACOALCOS"}
        subHeading={"Instituto Tecnológico Superior de Coatzacoalcos"}
        fromDate={"2014"}
        toDate={"2018"}
      />
    </div>,
   
    <div
      className="resume-screen-container programming-skills-container"
      key="prigramming-skills"
    >
      {programmingSkillDetails.map((skill, index) => (
        <div className="skill-parent" key={index}>
          <div className="heading-bullet"></div>
          <span>{skill.skill}</span>
          <div className="skill-percentage">
            <div
              style={{ width: skill.ratingPercentage + "%" }}
              className="active-percentage-bar"
            ></div>
          </div>
        </div>
      ))}
    </div>,
    <div className="resume-screen-container" key="projects">
      {propject.map((propjectDetails, index) => (
        <ResumeHeading
          key={index}
          heading={propjectDetails.title}
          subHeading={propjectDetails.subHeading}
          description={propjectDetails.description}
          fromDate={propjectDetails.duration.fromDate}
          toDate={propjectDetails.duration.toDate}
          
        />
      ))}
    </div>,
    <div className="resume-screen-container" key="interests">
      <ResumeHeading
        heading={i18n.t("interests_title")}
        subHeading={i18n.t("interests_subHeading")}
        description={i18n.t("interests_description")}
      />
       <ResumeHeading
        heading={i18n.t("interests_title2")}
        subHeading={i18n.t("interests_subHeading2")}
        description={i18n.t("interests_description2")}
      />
    </div>,
  ];

  //   const fadeInSubscription =
  //     ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);
  const handleCarousal = (index) => {
    let offsetHeight = 360;
    let newCarousalOffsert = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };
    setCarousalOffSetStyle(newCarousalOffsert);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? "bellet selected-bullet" : "bullet"
        }
        key={index}
      >
        <img
          className="bullet-logo"
          src={require(`../../assets/Resume/${bullet.logoSrc}`)}
          alt="oops,,, no internet connection"
        />
        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  };

  const getResumeScreen = () => {
    return (
      <div
        style={carousalOffSetStyle.style}
        className="resume-details-carousal"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  return (
    
    <div className="resume-container screen-container" id={props.id || ""}>
      <div className="resume-content">
        <ScreenHeading title={"Resume"} subHeading={i18n.t("resume_details")} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>
          <div className="resume-bullets-details">{getResumeScreen()}</div>
        </div>
      </div>
      
<Comunidad/>
    </div>

  );
}
