import './App.css';
import PortfolioContainer from './PorfolioContainer/PortfolioContainer';

function App() {
  return (
    <div className="App">
     <PortfolioContainer/>  
    
    </div>
  );
}

export default App;
