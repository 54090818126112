import React, { useEffect, useState, useRef } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import Footer from '../Home/Footer/Footer';
import './Comunidad.css'
import i18n from '../../i18n/index';
export default function Comunidad() {


    const videoRef = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.tiktok.com/embed.js';
        script.async = true;
        videoRef.current.appendChild(script);

        return () => {
            videoRef.current.removeChild(script);
        };
    }, []);
    return (
        <div className="comunidad-container screen-container">
            <div className="resume-content">
                <br></br>
                <ScreenHeading title= {i18n.t('comunidad')} />

                <div className="video-container" ref={videoRef}>



                    <iframe
                        title="Youtube"
                        width="640"
                        height="400"
                        src="https://www.youtube.com/embed/videoseries?si=Cdp-qRV3tuXrcwQC&amp;list=PLZsdWF8vLloQK2qmHJ5gPJxMFn45xk1Vf"
                        frameBorder="0"
                        allowFullScreen
                    />
                    <iframe
                        title="TikTok Profile"
                        width="640"
                        height="540"
                        src="https://manuelavalos.dev/tiktok.html"
                        frameBorder="0"
                        allowFullScreen
                    />
                    <iframe src="https://podcasters.spotify.com/pod/show/avalostech/embed" height="102px" width="640px" frameborder="0" scrolling="no"></iframe>
                


                </div>

            </div>
            <Footer />
        </div>
    )
}
