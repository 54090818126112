export const eng = {
    "Home": 'Home',
    "AboutMe": 'AboutMe',
    "Resume": 'Resume',
    "ContactMe": 'ContactMe',
    "greeting": "Hi, I'm",
    "name": "Manuel Avalos",
    "from": " I'm From 🇲🇽 🌮🌮",
    "role1": "Ethusiastic Dev 🤖",
    "role2": "Full Stack Developer 💻",
    "role3": "Clouding in Azure 🌐",
    "role4": "MERN Stack Dev 😃",
    "role5": "Cross Platform 🌐",
    "role6": "React/React Native Dev 🤳",
    "role7": "Passionate Developer 🤖",
    "tagline": "Only crazy people who think they can change the world can really change it. -Steve Jobs",
    "Hire_Me": "Hire Me",
    "Get_Resume": "Get Resume",
    "Profile_Github": "Profile Github",
    "about_me": "About me",
    "why_choose_me": "Why Choose Me?",
    "about_me_descripcion": "I am a Full Stack Developer, a person who is passionate about web development and the technologies that make it possible. I love working on challenging projects and creating innovative solutions that solve real world problems.Since my early youth, I have been interested in programming and technology, and have dedicated my career to becoming an expert in the field of web development. I have gained both server-side and client-side skills, and can design, develop, and maintain end-to-end web sites and applications.As a Full Stack Developer, I have experience in a variety of technologies, from HTML, CSS, and JavaScript on the front-end, to PHP, LARAVEL, and Python on the back-end. Also, I have skills in databases like MySQL, as well as web servers and automation tools.      ",
    "Highlights_title": "Here are a Few Highlights",
    "bullets_1": "Ability to work on both the server side and the client side of web development.",
    "bullets_2": "Knowledge of various technologies, including programming languages, databases, web servers, and automation tools.",
    "bullets_3": "Ability to design, develop, and maintain end-to-end web sites and applications.",
    "bullets_4": "Ability to collaborate with teams and communicate effectively in distributed or remote work environments.",
    "bullets_5": "Flexibility to adapt to different technologies and programming languages ​​as needed for each project.",
    "bullets_6": "Ability to work on both the server side and the client side of web development.",
    "resume_details": "My Formal Bio Details",
    "education": "Education",
    "work_experience": "Work Experience 🤫",
    "programming_skills": "Programming Skills",
    "projects": "Projects",
    "interests": "Interests",
    "platzy": "Platzy, Online Education",
    "platzy_description": "The online learning community where you develop your skills and boost your professional growth. <a href='https://platzi.com/r/Manuelavalos' target='_blank'>See profile</a>",
    "platzy_toDate": "in progress 🚀",
    "resume_job": "Cappa Software, REMOTE",
    "resume_job_description": "In my current role, I serve as the key orchestrator for the implementation of APIs, utilizing cutting-edge technologies such as JavaScript, Bootstrap, and PHP in the backend. My contributions unfold within a microservices environment, where I excel in crafting highly efficient and scalable solutions. Going beyond mere technical execution, my passion lies in conceiving and developing innovative solutions that not only meet requirements but also significantly elevate project performance and effectiveness. I am committed to delivering results that surpass expectations and am enthusiastic about the opportunity to bring my technical expertise and creativity to your team.",
    "job_habilidades": "✅Ability to design and develop highly efficient and scalable APIs using technologies.",
    "job_habilidades2": "✅Knowledge and experience in the development of applications based on microservices, which allows the creation of highly scalable and flexible solutions.",
    "resume_job2": "Cappa Software, REMOTE",
    "resume_job_description2": "Currently, I lead a team in the enhancement and expansion of an e-Learning solutions platform dedicated to corporate training. My responsibilities include task allocation, strategic meeting organization, and project management. In my role, I develop new modules for the platform, utilizing PHP as a programming language. Additionally, I design and create APIs to effectively connect with the platform's frontend, while maintaining a robust MYSQL database. To ensure an efficient workflow, we use tools like Notion for sprint planning and send detailed periodic reports to our clients to keep them informed about project progress. I also closely collaborate with our clients to identify and document their specific requirements, ensuring that the platform meets their corporate training needs accurately and effectively. Additionally, as an integral part of my role, I make modifications to the user view to enhance the user experience, applying best practices in interface design and user experience (UX) to ensure that our platform is intuitive and effective for our end users.",
    "job_2_habilidades": "✅Strategic planning · Relational database · Problem solving · API development · PHP · MySQL · Notion · User experience · Project management · Client communication · User experience design (UX) · Software development · Teamwork",
    "projects_title_1" : "Web Development Agency",
    "projects_description_1" : "We focus on putting our clients at the center of everything we do, which is why we have implemented a customer service model specialized in technology development to provide them with exceptional service.",
    "projects_subHeading_1" : "Strategic vision, leadership, communication skills, and customer orientation.",
    "projects_title_2" : "Personal Portfolio Website",
    "projects_description_2" : "A personal portfolio website to showcase all my projects in one place.",
    "projects_subHeading_2" : "Technologies used: React js, HTML and CSS",
    "interests_title": "🤝Content Creator",
    "interests_description": "I love sharing my knowledge with the community, which is why I like to create content in my free time. Also, I like to learn new things and stay updated with the latest technologies.",
    "interests_subHeading": "influencer ",
    "interests_title2": "🤖Artificial Intelligence",
    "interests_description2": "I like to learn about AI and how it can help humanity in the future.",
    "interests_subHeading2": "Future AI",
    "comunidad": "Community",
    "client": "Work with Clients",


}   

export default eng;