import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { eng } from './en';
import { esp } from './es';



var palabra = "es"; // Puedes cambiar esto a la palabra que deseas verificar
var $idioma = "";
if (window.navigator.language.includes(palabra)) {
   $idioma = "es";
} else {
   $idioma = "en";
}
if (window.location.pathname.substring(1, 3) === 'en') {
  $idioma = "en";
}

i18next
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false,
    },
    
    lng: $idioma,
    resources: {
      en: {
        translation: eng,
      },
      es: {
        translation: esp,
      },
    },
  }, (err, t) => {
    
  });

export default i18next;
