import React, { useState } from "react";
import Typical from "react-typical";
import axios from 'axios';
import { toast } from 'react-toastify';
import imgBack from "../../../src/images/mailz.jpeg";
import load1 from "../../../src/images/load2.gif";
import tiktok from "../../../src/assets/fonts/logo-tiktok.svg";
import instagram from "../../../src/assets/fonts/logo-instagram.svg";
import linkeding from "../../../src/assets/fonts/logo-linkeding.svg";
import twitter from "../../../src/assets/fonts/TwitterX.png";
import treadsicon from "../../../src/assets/fonts/treadsicon.png";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import Footer1 from "../../PorfolioContainer/Footer/Footer";
import "./ContactMe.css";

export default function ContactMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  // const fadeInSubscription = ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [banner, setBanner] = useState("");
  const [bool, setBool] = useState(false);

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      let data = {
        name,
        email,
        message,
      };
      setBool(true);
      const res = await axios.post(`/contact`, data);
      if (name.length === 0 || email.length === 0 || message.length === 0) {
        setBanner(res.data.msg);
        toast.error(res.data.msg);
        setBool(false);
      } else if (res.status === 200) {
        setBanner(res.data.msg);
        toast.success(res.data.msg);
        setBool(false);

        setName("");
        setEmail("");
        setMessage("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="main-container" id={props.id || ""}>
      <ScreenHeading subHeading={"Lets Keep In Touch"} title={"Contact Me"} />
      <div className="central-form">
        
        <div className="col">
          <h2 className="title">
            <Typical loop={Infinity} steps={["Get In Touch 📨", 1000]} />
          </h2>
          <a href='https://www.linkedin.com/in/manuelavalosdev' target="_blank">
                <img src={linkeding} alt='Manuel Avalos linkedin icono' width="30px" />
              </a>
              <a href='https://twitter.com/manuelavalosdev' target="_blank">
                <img src={twitter} alt='Manuel Avalos twitter icono' width="45px" />
              </a>
              <a href='https://www.instagram.com/manuelavalosdev/' target="_blank">
                <img src={instagram} alt='Manuel Avalos instagram icono' width="30px" />
              </a>
              <a href='https://www.tiktok.com/@solitarioavalos' target="_blank">
                <img src={tiktok} alt='Manuel Avalos tiktok icono' width="30px" />
              </a>
              <a href='https://www.threads.net/@manuelavalosdev' target="_blank">
                <img src={treadsicon} alt='Manuel Avalos threds icono' width="30px" />
              </a>
        </div>
        <div className="back-form">
          <div className="img-back">
            
            <h4>Send Your Email Here!</h4>
            <img src={imgBack} alt="image not found" />
          </div>
          <form onSubmit={submitForm}>
            <p>{banner}</p>
            <label htmlFor="name">Name</label>
            <input type="text" onChange={handleName} value={name} />

            <label htmlFor="email">Email</label>
            <input type="email" onChange={handleEmail} value={email} />

            <label htmlFor="message">Message</label>
            <textarea type="text" onChange={handleMessage} value={message} />
            <div className="send-btn">
              <button type="submit">
                Send <i className="fa fa-paper-plane" />
                {bool ? (<b className="load">
                  <img src={load1} alt="loading" /></b>) : ("")}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer1 />
    </div>

  );
}
