import Home from "../PorfolioContainer/Home/Home";
import AboutMe from "../PorfolioContainer/AboutME/AboutMe";
import Resume from "../PorfolioContainer/Resume/Resume";
import ContactMe from "../PorfolioContainer/ContactMe/ContactMe"; 
import Client from "../PorfolioContainer/Client/Client"; 


export const TOTAL_SCREENS = [
  {
    screen_name: "Home",
    component: Home,
  },
  {
    screen_name: "AboutMe",
    component: AboutMe,
  },
  {
    screen_name: "Resume",
    component: Resume,
  },
  {
    screen_name: "Client",
    component: Client,
  },
  {
    screen_name: "ContactMe",
    component: ContactMe,
  },
 
  
  

];
export const LANGUAGE_OPTIONS = [
  { label: 'English', value: 'en', css: 'bandera-estadosunidos.png' },
  { label: 'Español', value: 'es', css: 'bandera-mexico.png'},
];
export const GET_SCREEN_INDEX = (screen_name) => {
  if (!screen_name) return -1;
  for (let i = 0; i < TOTAL_SCREENS.length; i++) {
    if (TOTAL_SCREENS[i].screen_name === screen_name) return i;
  }

  return -1;
};
