import React, { useEffect, useState, useRef } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import './Client.css'
import i18n from '../../i18n/index';
export default function Client() {

        const images = [
            'https://micursor.com/wp-content/uploads/brizy/2/assets/images/iW=165&iH=47&oX=1&oY=0&cW=163&cH=47/LOGO-MI-CURSOR_2-1.png',  
            'https://codespace.mx/wp-content/uploads/2023/04/BLAZTY_LOGO_MN.png.webp','https://codespace.mx/wp-content/uploads/2023/03/Sin-titulo5122131-300x300.png',
            'https://codespace.mx/wp-content/uploads/2023/03/cropped-LOGO-TDS-EXPRESS_HCFB-1-e1667274636349-1-e1667704542342.png.webp',
            
          
            'https://media.licdn.com/dms/image/C560BAQF88KOV41HlSA/company-logo_200_200/0/1646840876119/cappasoftware_logo?e=2147483647&v=beta&t=VgqEGOEDthOvmfhLnF38jKNJOX0F749sDVDxf6tPpGs',
            // Agrega más imágenes de tus clientes aquí
        ];

 
    return (
        <div className="resume-container screen-container">
            <div className="resume-content">
                <br></br>
                <ScreenHeading title={i18n.t('client')} />
           
                <div className="carousel-container">
               
                <div className="carousel">
                    {images.map((image, index) => (
                        <div class="image-wrapper"><img key={index} src={image} alt={`Cliente ${index + 1}`}  /></div>
                    ))}
                    
                </div>
                
            </div>

            </div>
           
        </div>
    )
}
