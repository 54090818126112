import React, { useEffect, useRef } from 'react';
import Typical from 'react-typical';
import './Profile.css';
import tiktok from "../../../assets/fonts/logo-tiktok.svg";
import instagram from "../../../assets/fonts/logo-instagram.svg";
import linkeding from "../../../assets/fonts/logo-linkeding.svg";
import twitter from "../../../assets/fonts/TwitterX.png";
import treadsicon from "../../../assets/fonts/treadsicon.png";
import ScrollService from "../../../utilities/ScrollService";
import i18n from '../../../i18n/index';

export default function Profile() {


  return (
    <div className='profile-container' id='profileHome'>
      <div className='profile-parent'>
        <div className='profile-datails'>
          <div className='colz'>
            <div className='colz-icon'>
              <a href='https://www.linkedin.com/in/manuelavalosdev' target="_blank">
                <img src={linkeding} alt='Manuel Avalos linkedin icono' width="30px" />
              </a>
              <a href='https://twitter.com/manuelavalosdev' target="_blank">
                <img src={twitter} alt='Manuel Avalos twitter icono' width="45px" />
              </a>
              <a href='https://www.instagram.com/manuelavalosdev/' target="_blank">
                <img src={instagram} alt='Manuel Avalos instagram icono' width="30px" />
              </a>
              <a href='https://www.tiktok.com/@solitarioavalos' target="_blank">
                <img src={tiktok} alt='Manuel Avalos tiktok icono' width="30px" />
              </a>
              <a href='https://www.threads.net/@manuelavalosdev' target="_blank">
                <img src={treadsicon} alt='Manuel Avalos threds icono' width="30px" />
              </a>
            </div>
          </div>

          <div className='profile-details-name'>
            <span className='primary-text'>
              {i18n.t('greeting')} <span className='highlighted-text'> {i18n.t('name')} </span>
              {i18n.t('from')}
            </span>
          </div>

          <div className='profile-details-role'>
            <span className='primary-text'>
              <h1>
                <Typical
                  loop={Infinity}
                  steps={[
                    `${i18n.t('role1')}`,
                    1000,
                    `${i18n.t('role2')}`,
                    1000,
                    `${i18n.t('role3')}`,
                    1000,
                    `${i18n.t('role4')}`,
                    1000,
                    `${i18n.t('role5')}`,
                    1000,
                    `${i18n.t('role6')}`,
                    1000,
                    `${i18n.t('role7')}`,
                    1000,
                  ]}
                />
              </h1>
              <span className='profile-role-tagline'>
                {i18n.t('tagline')}
              </span>
            </span>
          </div>

          <div className='profile-options'>
            <button
              className="btn primary-btn"
              onClick={() => ScrollService.scrolHandler.scrollToHireMe()}
            >
             {i18n.t('Hire_Me')}
            </button>
            <button
              className="btn primary-btn github-btn"
              onClick={() => window.open("https://github.com/manuelavalosave", "_blank")}
            >
             {i18n.t('Profile_Github')}
            </button>


            <a href="#ContactMe"></a>
            <a href='manuel avalos Cv.pdf' download='Manuel Avalos'>
              <button className='btn highlighted-btn'>{i18n.t('Get_Resume')}</button>
            </a>
          </div>
        </div>

        <div className='profile-picture'>
          <div className='profile-picture-background'></div>
        </div>
      </div>


    </div>
  );
}
