import React, { useState } from "react";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook
import {
  TOTAL_SCREENS,
  LANGUAGE_OPTIONS,
  GET_SCREEN_INDEX,
} from "../../../utilities/commonUtils";
import i18n from '../../../i18n/index';
import ScrollService from "../../../utilities/ScrollService";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Header.css";

export default function Header() {
  const [selectedScreen, setSelectedScreen] = useState(0);
  const [showHeaderOptions, setShowHeaderOptions] = useState(false);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false); // Nuevo estado para el menú de idiomas

  const { t } = useTranslation(); // Use the useTranslation hook to get the t function


  const updateCurrentScreen = (currentScreen) => {
    if (!currentScreen || !currentScreen.screenInView) return;
    let screenIndex = GET_SCREEN_INDEX(currentScreen.screenInView);
    if (screenIndex < 0) return;
  };

  let currentScreenSubscription =
    ScrollService.currentScreenBroadCaster.subscribe(updateCurrentScreen);

  const getHeaderOptionsClass = (index) => {
    let classes = " ";

    if (index < TOTAL_SCREENS.length - 1) {
      classes += "header-option-separator";
    }

    if (selectedScreen === index) {
      classes += " selected-header-option";
    }

    return classes;
  };

  const getHeaderOptions = () => {
    return TOTAL_SCREENS.map((screen, i) => {
      if (screen.screen_name === "Client") {
        return null; // Avoid showing the element with screen_name: "Client"
      }
      const translatedText = i18n.t(screen.screen_name);
      const language = window.location.pathname.substring(1, 2) === 'es' ? 'es' : 'en';
    

      return (
        <div
          key={screen.screen_name}
          className={getHeaderOptionsClass(i)}
          onClick={() => switchScreen(i, screen)}
        >
          <span>{translatedText}</span>
        </div>
      );
    });
  };

  const getLanguageOptions = () => {
    return LANGUAGE_OPTIONS.map((option) => (
      <li>
        <img src={require(`../../../assets/Home/${option.css}`)} alt="oops,,, no internet connection" width="18px" />
        <a
          className={`${option.css} ${window.location.pathname === `/${option.value}` ? 'current-page' : ''}`}
          href={`/${option.value}`}
        >
          {option.label}
        </a>
      </li>



    ));
  };

  const switchScreen = (index, screen) => {
    let screenComponent = document.getElementById(screen.screen_name);
    if (!screenComponent) return;

    screenComponent.scrollIntoView({ behavior: "smooth" });
    setSelectedScreen(index);
    setShowHeaderOptions(false);
  };
  const toggleLanguageMenu = () => {
    setShowLanguageMenu(!showLanguageMenu);
    setShowHeaderOptions(true);
  };
  return (
    <div>
      <div
        className="header-container"
        onClick={() => setShowHeaderOptions(!showHeaderOptions)}
      >
        <div className="header-parent">
          <div
            className="header-hamburger"
            onClick={() => setShowHeaderOptions(!showHeaderOptions)}
          >
            <FontAwesomeIcon className="header-hamburger-bars" icon={faBars} />
          </div>
          <div className="header-logo">
            <span>Manuel Avalos</span>
          </div>
          <div
            className={
              showHeaderOptions
                ? "header-options show-hamburger-options"
                : "header-options "
            }
          >
            {getHeaderOptions()}
            <div className="header-option-separator"></div>
            <div class="Traductor" onClick={toggleLanguageMenu}>
              <i class="fa fa-language"></i>
              <ul class={showLanguageMenu ? "menu-traductor active" : "menu-traductor"}>
        
                {getLanguageOptions()}

              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
